body {
  font-family: 'Open Sans', sans-serif;
  background-color: #F8F8F8;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

div {
  box-sizing: border-box;
}

p {
  margin: 0
}
