body {
  font-family: 'Open Sans', sans-serif;
  background-color: #FFFFFF;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2 !important;
}

div {
  box-sizing: border-box;
}

p {
  margin: 0 !important;
}

.flag-select__btn {
  margin-top: -6px !important;
}

.flag-select__btn:after {
  display: none !important;
}
