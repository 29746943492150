@media print {
  .footer {
    height: 100px;
    background-color: #FFFFFF;
    width: 100%;
    padding-left: 50px;
    padding-top: 30px;
  }

  .footer p {
    color: black;
    font-size: 24px;
  }

  .codeText {
    color: #000000;
    background-color: #FFFFFF;
    padding: 0,
  }
}

@media screen {
  .footer {
    height: 100px;
    background-color: #000000;
    width: 100%;
  }

  .footer p {
    color: white;
    font-size: 24px;
    padding-left: 50px;
    padding-top: 30px;
  }

  .codeText {
    color: #FFFFFF;
    background-color: #000000;
    padding: 10px,
  }
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  background: none;
}

div {
  box-sizing: border-box;
}

p {
  margin: 0
}

