body {
  font-family: 'Open Sans', sans-serif;
  background-color: #FFFFFF;
  color: #000000;
  font-size: 16px;
  font-weight: 400; }

.nav-payment {
  height: 40px;
  background-color: #44BDDC;
  width: 100%;
  border: none;
  color: #FFFFFF;
  text-align: center;
  padding-top: 15px; }
